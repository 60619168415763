<template>
  <div class="wx_home">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card class="box-card box-item">
          <div slot="header" class="clearfix">
            <span>顶部轮播设置</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleUpdate('topSwiper')">更新</el-button>
          </div>
          <el-row :gutter="10">
            <el-col :span="3">图片</el-col>
            <el-col :span="9">名称</el-col>
            <el-col :span="5">跳转类型</el-col>
            <el-col :span="4">对应ID</el-col>
            <el-col :span="3">操作</el-col>
          </el-row>
          <div class="top-swiper-list-area">
            <el-row class="top-swiper-list-item" :gutter="10" v-for="(swiper, index) in topSwiper" :key="index">
              <el-col :span="3">
                <div class="upload-image-wrap">
                  <i v-if="swiper.image === ''" class="el-icon-picture image_upload_icon"></i>
                  <img v-else :src="swiper.image" class="upload_image">
                  <input type="file" class="image_input" accept="image/png,image/gif,image/jpeg" :data-index="index" data-item="topSwiper" @change="handlePhotoChange">
                </div>
              </el-col>
              <el-col :span="9">
                <el-input v-model="swiper.name" clearable maxlength="10" size="mini"></el-input>
              </el-col>
              <el-col :span="5">
                <el-select v-model="swiper.type" placeholder="请选择" size="mini">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-input-number style="width:100%;" v-model="swiper.tar" size="mini" :controls="false"></el-input-number>
              </el-col>
              <el-col :span="3">
                <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="deleteItem(index,'topSwiper')"></el-button>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col>
              <el-button type="text" @click="addItem('topSwiper')"><i class="el-icon-circle-plus-outline"></i>添加轮播图片</el-button>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="box-card box-item">
          <div slot="header" class="clearfix">
            <span>精选套餐轮播设置</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleUpdate('suitSwiper')">更新</el-button>
          </div>
          <el-row :gutter="10">
            <el-col :span="3">图片</el-col>
            <el-col :span="9">名称</el-col>
            <el-col :span="5">跳转类型</el-col>
            <el-col :span="4">对应ID</el-col>
            <el-col :span="3">操作</el-col>
          </el-row>
          <div class="top-swiper-list-area">
            <el-row class="top-swiper-list-item" :gutter="10" v-for="(swiper, index) in suitSwiper" :key="index">
              <el-col :span="3">
                <div class="upload-image-wrap">
                  <i v-if="swiper.image === ''" class="el-icon-picture image_upload_icon"></i>
                  <img v-else :src="swiper.image" class="upload_image">
                  <input type="file" class="image_input" accept="image/png,image/gif,image/jpeg" :data-index="index" data-item="suitSwiper" @change="handlePhotoChange">
                </div>
              </el-col>
              <el-col :span="9">
                <el-input v-model="swiper.name" clearable maxlength="10" size="mini"></el-input>
              </el-col>
              <el-col :span="5">
                <el-select v-model="swiper.type" placeholder="请选择" size="mini">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-input-number style="width:100%;" v-model="swiper.tar" size="mini" :controls="false"></el-input-number>
              </el-col>
              <el-col :span="3">
                <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="deleteItem(index,'suitSwiper')"></el-button>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col>
              <el-button type="text" @click="addItem('suitSwiper')"><i class="el-icon-circle-plus-outline"></i>添加轮播图片</el-button>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="box-card box-item">
          <div slot="header" class="clearfix">
            <span>探索更多轮播设置</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleUpdate('moreSwiper')">更新</el-button>
          </div>
          <el-row :gutter="10">
            <el-col :span="4">图片</el-col>
            <el-col :span="8">名称</el-col>
            <el-col :span="5">跳转类型</el-col>
            <el-col :span="4">对应ID</el-col>
            <el-col :span="3">操作</el-col>
          </el-row>
          <div class="top-swiper-list-area">
            <el-row class="top-swiper-list-item" :gutter="10" v-for="(swiper, index) in moreSwiper" :key="index">
              <el-col :span="4">
                <div class="upload-image-wrap">
                  <i v-if="swiper.image === ''" class="el-icon-picture image_upload_icon"></i>
                  <img v-else :src="swiper.image" class="upload_image">
                  <input type="file" class="image_input" accept="image/png,image/gif,image/jpeg" :data-index="index" data-item="moreSwiper" @change="handlePhotoChange">
                </div>
              </el-col>
              <el-col :span="8">
                <el-input v-model="swiper.name" clearable maxlength="10" size="mini"></el-input>
              </el-col>
              <el-col :span="5">
                <el-select v-model="swiper.type" placeholder="请选择" size="mini">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-input-number style="width:100%;" v-model="swiper.tar" size="mini" :controls="false"></el-input-number>
              </el-col>
              <el-col :span="3">
                <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="deleteItem(index,'moreSwiper')"></el-button>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col>
              <el-button type="text" @click="addItem('moreSwiper')"><i class="el-icon-circle-plus-outline"></i>添加轮播图片</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card box-item">
          <div slot="header" class="clearfix">
            <span>Furniture Cellection家具分类设置</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleUpdate('typeone')">更新</el-button>
          </div>
          <el-row :gutter="10">
            <el-col :span="4">图片</el-col>
            <el-col :span="8">名称</el-col>
            <el-col :span="5">跳转类型</el-col>
            <el-col :span="4">对应ID</el-col>
            <el-col :span="3">操作</el-col>
          </el-row>
          <div class="top-swiper-list-area">
            <el-row class="top-swiper-list-item" :gutter="10" v-for="(category, index) in typeone" :key="index">
              <el-col :span="4">
                <div class="upload-image-wrap">
                  <i v-if="category.image === ''" class="el-icon-picture image_upload_icon"></i>
                  <img v-else :src="category.image" class="upload_image">
                  <input type="file" class="image_input" accept="image/png,image/gif,image/jpeg" :data-index="index" data-item="typeone" @change="handlePhotoChange">
                </div>
              </el-col>
              <el-col :span="8">
                <el-input v-model="category.name" clearable maxlength="10" size="mini"></el-input>
              </el-col>
              <el-col :span="5">
                <el-select v-model="category.type" placeholder="请选择" size="mini">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-input-number style="width:100%;" v-model="category.tar" size="mini" :controls="false"></el-input-number>
              </el-col>
              <el-col :span="3">
                <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="deleteItem(index,'typeone')"></el-button>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col>
              <el-button type="text" @click="addItem('typeone')"><i class="el-icon-circle-plus-outline"></i>添加轮播图片</el-button>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="box-card box-item">
          <div slot="header" class="clearfix">
            <span>Space Function 功能区域设置</span>
            <el-button style="float: right; padding: 3px 0" type="text"  @click="handleUpdate('spaceFunction')">更新</el-button>
          </div>
          <el-row :gutter="10">
            <el-col :span="4">图片</el-col>
            <el-col :span="8">名称</el-col>
            <el-col :span="5">跳转类型</el-col>
            <el-col :span="4">对应ID</el-col>
            <el-col :span="3">操作</el-col>
          </el-row>
          <div class="top-swiper-list-area">
            <el-row class="top-swiper-list-item" :gutter="10" v-for="(space, index) in spaceFunction" :key="index">
              <el-col :span="4">
                <div class="upload-image-wrap">
                  <i v-if="space.image === ''" class="el-icon-picture image_upload_icon"></i>
                  <img v-else :src="space.image" class="upload_image">
                  <input type="file" class="image_input" accept="image/png,image/gif,image/jpeg" :data-index="index" data-item="spaceFunction" @change="handlePhotoChange">
                </div>
              </el-col>
              <el-col :span="8">
                <el-input v-model="space.name" clearable maxlength="10" size="mini"></el-input>
              </el-col>
              <el-col :span="5">
                <el-select v-model="space.type" placeholder="请选择" size="mini">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-input-number style="width:100%;" v-model="space.tar" size="mini" :controls="false"></el-input-number>
              </el-col>
              <el-col :span="3">
                <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="deleteItem(index,'spaceFunction')"></el-button>
              </el-col>
            </el-row>
          </div>
          <el-row>
            <el-col>
              <el-button type="text" @click="addItem('spaceFunction')"><i class="el-icon-circle-plus-outline"></i>添加轮播图片</el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card box-item">
          <div slot="header" class="clearfix">
            <span>精选管理</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="updateJx">更新</el-button>
          </div>
          <div class="jx-body">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item :title="item.name" :name="index" v-for="(item,index) in jx" :key="index">
                <div class="goods-list">
                  <div class="goods-image-wrap" v-for="(g,i) in item.goods" :key="i">
                    <i class="el-icon-circle-close goods-delete-icon" @click="removeImage(index,i)"></i>
                    <el-image style="width: 60px; height: 60px" :src="g.image" fit="fill"></el-image>
                  </div>
                  <div class="add-jx-item-wrap" @click="showJXgoodsdialog(index)">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
                <el-popconfirm title="确定删除该分类吗？" @confirm="removeJxItem(index)">
                  <el-button slot="reference" type="text" style="color:red;"><i class="el-icon-remove-outline"></i>删除该分类</el-button>
                </el-popconfirm>
              </el-collapse-item>
            </el-collapse>
          </div>
          <el-button type="text" @click="dialogAddJX = true; newJxName = ''"><i class="el-icon-circle-plus-outline"></i>添加精选分类</el-button>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="新增精选分类" :visible.sync="dialogAddJX" width="30%">
      <el-input v-model="newJxName" placeholder="请输入分类名" maxlength="10"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAddJX = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addJxItem" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="关联商品" :visible.sync="dialogJXgoods" width="30%" top="10vh">
      <el-input v-model="search" placeholder="请输入要搜索的关键词，例如标题中的具体型号" maxlength="10">
        <template slot="append">
          <el-button icon="el-icon-search" @click="handleSearch"></el-button>
        </template>
      </el-input>
        <el-table :data="goodsList" style="width: 100%" size="mini" height="500" :show-header="false" :v-loading="loading" ref="goodsTable">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px" :src="scope.row.image" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="title"></el-table-column>
      </el-table>
      <el-button type="text" v-if="goodsCount > goodsList.length" @click="handleNextPage">加载更多</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogJXgoods = false; search = ''; goodsList = [];goodsCount = 0; goodsPage = 1;" size="mini">取 消</el-button>
        <el-button type="primary" @click="comfirmJxGoods" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'wx_home',
  data () {
    return {
      jx: [],
      topSwiper: [],
      suitSwiper: [],
      moreSwiper: [],
      typeone: [],
      spaceFunction: [],
      typeOptions: [{
        value: 1,
        label: '分类'
      }, {
        value: 2,
        label: '商品'
      }],
      activeName: 0,
      dialogAddJX: false,
      newJxName: '',
      dialogJXgoods: false,
      search: '',
      loading: false,
      goodsList: [],
      goodsCount: 0,
      goodsPage: 1,
      activeJxItem: null
    }
  },
  methods: {
    handlePhotoChange (e) {
      var that = this
      var file = e.target.files[0]
      var index = e.target.dataset.index
      var item = e.target.dataset.item
      var param = new FormData()
      param.append('image', file)
      this.axios.post('admin/home/image', param)
        .then(function (response) {
          if (response.data.code === 0) {
            that[item][index].image = response.data.url
          }
        })
    },
    addItem (item) {
      this[item].push({ name: '', image: '', type: 1, tar: 1, item: item })
    },
    deleteItem (index, item) {
      this[item].splice(index, 1)
    },
    handleUpdate (item) {
      const that = this
      var data = this[item]
      var flag = true
      data.forEach(element => {
        for (const x in element) {
          if (element[x] === '') {
            flag = false
            break
          }
        }
      })
      if (flag) {
        data = { item: item, data: data }
        that.axios.put('admin/home/jump', data)
          .then(function (response) {
            if (response.data.code === 0) {
              that.$message.success('已更新')
            }
          })
      } else {
        that.$message('不符合更新要求')
      }
    },
    removeImage (index, i) {
      this.jx[index].goods.splice(i, 1)
    },
    removeJxItem (index) {
      this.jx.splice(index, 1)
    },
    addJxItem () {
      var flag = true
      this.jx.forEach(element => {
        if (this.newJxName.trim() === element.name) {
          flag = false
        }
      })
      if (flag && this.newJxName !== '') {
        this.jx.push({ name: this.newJxName, goods: [] })
        this.dialogAddJX = false
      } else {
        this.$message.error('分类名重复或为空，请换个分类名再试')
      }
    },
    handleSearch () {
      this.goodsList = []
      this.goodsPage = 1
      this.getGoodsList()
    },
    getGoodsList () {
      if (this.search !== '') {
        this.loading = true
        var that = this
        this.axios.get('admin/goods?title=' + that.search + '&page=' + that.goodsPage)
          .then(function (response) {
            that.loading = false
            that.goodsList = [...that.goodsList, ...response.data.list]
            that.goodsCount = response.data.count
          })
      }
    },
    handleNextPage () {
      this.goodsPage = this.goodsPage + 1
      this.getGoodsList()
    },
    comfirmJxGoods () {
      this.dialogJXgoods = false
      var goods = this.$refs.goodsTable.selection
      goods.forEach(element => {
        this.jx[this.activeJxItem].goods.push({ gid: element.id, image: element.image })
      })
    },
    showJXgoodsdialog (index) {
      this.dialogJXgoods = true
      this.search = ''
      this.goodsList = []
      this.goodsCount = 0
      this.goodsPage = 1
      this.activeJxItem = index
    },
    updateJx () {
      var names = []
      var goodsId = []
      for (let i = 0; i < this.jx.length; i++) {
        const element = this.jx[i]
        names[i] = element.name
        goodsId[i] = []
        for (let j = 0; j < element.goods.length; j++) {
          const gid = element.goods[j].gid
          goodsId[i][j] = gid
        }
      }
      var that = this
      this.axios.post('admin/home/jx', { names: names, goods: goodsId })
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message.success(response.data.msg)
          }
        })
    },
    getJxData () {
      var that = this
      this.axios.get('admin/home/jx')
        .then(function (response) {
          that.jx = response.data
        })
    },
    getJumpData () {
      var that = this
      that.axios.get('admin/home/jump')
        .then(function (response) {
          const data = response.data
          that.topSwiper = data.list.topSwiper === undefined ? [] : data.list.topSwiper
          that.suitSwiper = data.list.suitSwiper === undefined ? [] : data.list.suitSwiper
          that.moreSwiper = data.list.moreSwiper === undefined ? [] : data.list.moreSwiper
          that.typeone = data.list.typeone === undefined ? [] : data.list.typeone
          that.spaceFunction = data.list.spaceFunction === undefined ? [] : data.list.spaceFunction
        })
    }
  },
  mounted () {
    this.getJxData()
    this.getJumpData()
  }
}
</script>

<style scoped>
.top-swiper-list-area{
  padding: 15px 0 5px;
  box-sizing: border-box;
}
.top-swiper-list-item{
  padding: 5px 0;
}
.box-item{
  margin-bottom: 20px;
}
.upload-image-wrap{
  position: relative;
  width: 25px;
  height: 25px;
}
.upload_image,.image_input{
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}
.image_input{
  font-size: 0;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
}
.image_upload_icon{
  color: #a5a5a5;
  font-size: 30px;
  line-height: 30px;
}
.goods-list{
  display: flex;
  flex-wrap: wrap;
}
.goods-image-wrap{
  padding: 8px;
  position: relative;
}
.add-jx-item-wrap{
  width: 58px;
  height: 58px;
  border: 1px dashed #ccc;
  box-sizing: border-box;
  color: #ccc;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: 8px;
}
.add-jx-item-wrap > .el-icon-plus{
  font-size: 19px;
  margin: auto;
}
.goods-delete-icon{
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 14px;
  z-index: 2;
  color: rgb(172, 172, 172);
  cursor: pointer;
}
</style>
